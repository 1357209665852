@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next');
}

* {
  font-family: 'Avenir Next', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}